/* body{
  
  font-family: 'Roboto', sans-serif;
} */


/* ----------for screen size 15.6 inch---------- */
/* @media (min-width: 1400px){
  .container{
      max-width: 1370px;
    }
  } */

  .textWrapper {
    white-space: pre-wrap;
  }

  .ace_gutter-layer {
    background-color: #282828;
  }

  body {
    overflow: hidden;
  }

  .questionContent {
    font-weight: 500;
  }

  .questionContent code {
    background-color: #ffffff12;
    border-color: #f7faff1f;
    color: #eff1f6bf;
    border-radius: 5px;
    border-width: 1px;
    font-family: Menlo,sans-serif;
    font-size: .75rem;
    line-height: 1rem;
    padding: 0.125rem;
    padding-left: 0.250rem;
    padding-right: 0.250rem;
    white-space: pre-wrap;
    border-style: solid;
    box-sizing: border-box;
  }

  .questionContent pre {
    background-color: #ffffff12;
    color: #eff1f6bf;
    border-radius: 0.5rem;
    font-family: Menlo,sans-serif;
    font-size: .875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    white-space: pre-wrap;
  }

  .questionContent ul {
    list-style-type: disc;
    padding-inline-start: 20px;
  }

  .questionContent li {
    margin-bottom: 0.75rem;
  }

  .sc-ifAKCX {
    width: 100%;
  }