body {
  /* margin: 0;
  padding: 0;
  */
  font-feature-settings: "tnum";
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-variant: tabular-nums;
  font-size: 14px;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
.nextLineProperty {
  white-space:"pre-wrap";
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* 
body{
  font-family: 'Roboto', sans-serif;
} */

:root{
  color-scheme: 'dark';
  --scroll-bar-color: #999999;
  --scroll-bar-bg-color: 'transparent';
}

.gutter {
  /* background-color: #eee; */

  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-color: #1a1a1a;
  background-image: url('./common/assets/gutterVertical.svg');
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-color: #1a1a1a;
  background-image: url('./common/assets/gutterHorizontal.svg');
  cursor: row-resize;
}

em-emoji-picker {
  --rgb-accent: 16, 160, 160;
  --rgb-background: red;

  height: 25vh;
  min-height: 200px;
  max-height: 400px;
  width: 100%;
  min-width: 100%;
}

em-emoji > .emoji-mart-emoji > span {
  font-family: "Twemoji Country Flags", sans-serif !important;
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

    * {
        scrollbar-width: thin;
        scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--scroll-bar-bg-color);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--scroll-bar-color);
        border-radius: 20px;
        border: 3px solid var(--scroll-bar-bg-color);
    }